var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popup-modal',{ref:"popup"},[_c('div',{staticClass:"theme-modal"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_c('h4',[_vm._v("Course Selection")])]),_c('a',{staticClass:"close-modal",attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":_vm.closeImg,"alt":"Close"},on:{"click":_vm.closeModal}})])]),_c('div',{staticClass:"content"},_vm._l((_vm.courses),function(course,index){return (course.is_started == 1)?_c('div',{key:course.class_id,class:{ 
                'course-item': true, 
                'current': course.class_id == _vm.id_current_class && _vm.id_current_class == _vm.temp_id, 
                // 'current1': id_current_class != temp_id && id_current_class1 = id_current_class, 
                'active': course.class_id == _vm.temp_course.class_id,
                'current1': course.class_id == _vm.temp_id,
            },on:{"click":function($event){return _vm.selectCourse(course)}}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":course.illustration_url,"alt":""}})]),_c('div',{staticClass:"desc",on:{"mouseenter":function($event){return _vm.checkOverflow($event)},"mouseleave":function($event){return _vm.resetOverflow($event)}}},[_c('p',[_vm._v(_vm._s(course.program_name))]),_c('h5',[_vm._v(_vm._s(course.course_name))]),_c('p',{staticClass:"class-name"},[_vm._v(_vm._s(course.class_name))])])]),(false)?_c('div',{staticClass:"pacing"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"status"}),_c('div',{staticClass:"status-1"}),_c('div',{staticClass:"status-2"}),_c('div',{staticClass:"line"})])]):_vm._e()]):_vm._e()}),0),_c('div',{staticClass:"bottom"},[_c('button',{class:_vm.temp_course.class_id != _vm.temp_id && _vm.temp_course.class_id != undefined ? 'btn-change-course active' :  'btn-change-course',attrs:{"disabled":_vm.temp_course.class_id != _vm.temp_id && _vm.temp_course.class_id != undefined ? false : true},on:{"click":function($event){return _vm.changeCourse(_vm.temp_course)}}},[_vm._v("SWITCH COURSE")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }