import { render, staticRenderFns } from "./TestDetailPreview.vue?vue&type=template&id=e221835c&scoped=true"
import script from "./TestDetailPreview.vue?vue&type=script&lang=js"
export * from "./TestDetailPreview.vue?vue&type=script&lang=js"
import style0 from "./TestDetailPreview.vue?vue&type=style&index=0&id=e221835c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e221835c",
  null
  
)

export default component.exports