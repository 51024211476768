<template>
  <div id="lesson">
    <list-item @openItem="openItem" class="" ref="listItem"></list-item>
    <div class="header">
      <div
        @click="showListItem()"
        v-if="current_categories.items.length > 1"
        class="btn-menu small"
        style="position: relative"
      >
        <img
          v-if="showMenu"
          src="/themes/web/assets/images/icon/icon-menu.svg"
          alt=""
        />
        <img v-else src="/themes/web/assets/images/icon/icon-x.svg" alt="" />
        <div v-if="!showMenu" class="tools">
          <div class="" v-for="item in catagory.items" :key="item.id">
            <div
              @click="openItem(item)"
              style="display: flex; gap: 10px; margin-bottom: 10px"
            >
              <img
                v-if="item.id == learning_item.id"
                src="/themes/web/assets/images/icon/arrow-left-orange.svg"
                alt=""
              />
              <p :class="item.id == learning_item.id ? 'active' : ''">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="current_categories.items.length > 1">
        <button
          @click="learning_item.index != 0 ? pre(learning_item) : ''"
          style="margin-right: 8px"
          :class="
            learning_item.index != 0
              ? 'igs-btn small'
              : 'igs-btn small inactive'
          "
        >
          <img src="/themes/web/assets/images/icon/arrow-left.svg" alt="" />
        </button>
        <button
          @click="
            learning_item.index + 1 != current_categories.items.length
              ? next(learning_item)
              : ''
          "
          :class="
            learning_item.index + 1 != current_categories.items.length
              ? 'igs-btn small'
              : 'igs-btn small inactive'
          "
        >
          <img src="/themes/web/assets/images/icon/arrow-right.svg" alt="" />
        </button>
      </div>
      <!-- <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
            <p class="path">{{ lesson_name }}</p> -->
      <img
        v-if="current_categories.items.length > 1"
        class="icon-arow"
        src="/themes/web/assets/images/icon/icon-arrow-right.svg"
        alt=""
      />
      <p class="path">{{ current_categories.name }}</p>
      <img
        class="icon-arow"
        src="/themes/web/assets/images/icon/icon-arrow-right.svg"
        alt=""
      />
      <p class="path-1">{{ learning_item.name }}</p>
    </div>
    <div class="webview">
      <iframe
        allow="microphone *;clipboard-read *; clipboard-write *"
        :src="learning_item ? learning_item.content : ''"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getListItem } from "../../models/preview";
import listItem from "../modal/Learning/listItem.vue";

export default {
  name: "PreviewLessonPackage",
  components: { listItem },
  title: "PreviewLessonPackage",

  data() {
    return {
      current_categories: {},
      list_catagory: [],
      course_id: this.$route.query.course_id,
      module_id: this.$route.query.module_id,
      item_id: this.$route.query.item_id,
      lesson_id: this.$route.query.lesson_id,
      lesson_package_id: this.$route.query.lesson_package_id,
      class_id: this.$route.params.class_id,
      learning_item: {},
      lesson_name: "",
      showMenu: true,
      catagory: {},
    };
  },

  created() {
    // window.addEventListener('message', this.handleMessage);
    setTimeout(() => {
      this.getListItem();
    }, 300);
    window.addEventListener("message", this.handleMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },

  computed: {},

  methods: {
    handleMessage(event) {
      if (event.data.type == "close_iframe") {
        this.$router.push({
          path: "/preview-lesson-package",
          query: {
            course_id: this.course_id,
            module_id: this.module_id,
            lesson_id: this.lesson_id,
            lesson_package_id: this.lesson_package_id,
            item_id: item.id,
          },
        });
      }
    },

    async showListItem() {
      this.showMenu = !this.showMenu;
      if (!this.showMenu) {
        $(".btn-menu").css({ "border-radius": "8px 8px 0 0" });
      } else {
        $(".btn-menu").css({ "border-radius": "8px" });
      }
      // const cfm = await this.$refs.listItem.show({
      //     lang: this.lang,
      //     list_catagory: this.list_catagory,
      //     learning_item: this.learning_item,
      // });
      // if (!cfm) {
      //     return false;
      // }
    },

    openItem(item) {
      const currentRoute = this.$route.fullPath;
      const targetRoute =
        "/preview-lesson-package?course_id=" +
        this.course_id +
        "&module_id=" +
        this.module_id +
        "&lesson_id=" +
        this.lesson_id +
        "&lesson_package_id=" +
        this.lesson_package_id +
        "&item_id=" +
        item.id;

      if (targetRoute !== currentRoute) {
        const targetRoute = this.$router.push({
          path: "/preview-lesson-package",
          query: {
            course_id: this.course_id,
            module_id: this.module_id,
            lesson_id: this.lesson_id,
            lesson_package_id: this.lesson_package_id,
            item_id: item.id,
          },
        });
        this.learning_item = item;
      }
    },
    next() {
      this.openItem(
        this.current_categories.items[this.learning_item.index + 1]
      );
    },
    pre() {
      this.openItem(
        this.current_categories.items[this.learning_item.index - 1]
      );
    },
    getListItem() {
      const params = {
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
      };
      const that = this;
      that.isLoading = true;
      getListItem(params).then((response) => {
        that.isLoading = false;
        if (response.data.code != 0) {
          return;
        }

        that.lesson_name = response.data.results.lesson_name;
        that.list_catagory = response.data.results.categories;

        that.catagory = response.data.results.categories[0];

        if ((that.item_id || 0) == 0) {
          if (
            response.data.results.current_categories &&
            response.data.results.current_categories.id != 0
          ) {
            const filteredArray = that.list_catagory.filter(
              (item) => item.id == response.data.results.current_categories.id
            );
            const filteredItems = filteredArray[0].items;
            that.learning_item = filteredItems.find((e) => e.is_learning === 1);
            that.current_categories = that.list_catagory.find(
              (item) => item.id == response.data.results.current_categories.id
            );
          } else {
            that.learning_item = that.list_catagory[0].items[0];
            that.current_categories = that.list_catagory[0];
          }
        } else {
          const itemsIdToFind = that.item_id;
          const foundListItems = that.list_catagory.filter((category) => {
            return category.items.some((item) => item.id == itemsIdToFind);
          });
          that.current_categories = foundListItems[0];

          if (foundListItems.length > 0) {
            foundListItems.forEach((listItem) => {
              const itemsWithId = listItem.items.filter(
                (item) => item.id == that.item_id
              );
              if (itemsWithId.length > 0) {
                that.learning_item = itemsWithId[0];
              }
            });
          }
          console.log(that.current_categories, that.learning_item);
        }
      });
    },
  },
};
</script>

<style scoped>
.tools {
  position: absolute;
  z-index: 99;
  bottom: 0;
  border-radius: 0px 16px 16px 16px;
  background: var(--Creme-Dark, #f8fafc);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px 48px 18px 24px;
  left: 0;
  top: 36px;
  height: fit-content;
}
.tools img {
  position: absolute;
}
.tools p {
  color: var(--Liquorice-Light, #777);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.32px;
  margin-left: 30px;
  text-wrap: nowrap;
}
.tools p.active {
  color: var(--Pumpkin-Light, #fc7a1b);
}
#lesson {
  margin-right: 24px;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.webview {
  box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.25);
  background: #f8fafc;
  border-radius: 24px;
  /* height: 738px; */
  height: calc(100% - 100px);
  overflow: hidden;
}
.locked > p {
  color: var(--Cloud-Light, #ddd);
}
.igs-btn {
  height: 32px;
  width: 47px;
  background: #ffffff;
}
.igs-btn.inactive {
  background: #777;
  cursor: not-allowed;
}
.btn-menu {
  height: 36px;
  width: 47px;
  background: #ffffff;
  background: var(--Creme-Dark, #f8fafc);
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.path {
  letter-spacing: 0.02em;
  color: #00afff;
  font-weight: 500;
  font-size: 16px;
}
.path-1 {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 16px;
  color: #f8fe5f;
}
</style>
