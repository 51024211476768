<template>
    <div id="profile">
        <change-password-modal class="" ref="changePassword"></change-password-modal>
        <change-avatar-modal class="" ref="changeAvatar"></change-avatar-modal>
        <confirm-logout class="" ref="confirmLogout"></confirm-logout>
        <transcript-modal class="" ref="transcript"></transcript-modal>

        <div v-if="!is_transcript" class="left">
            <div>
                <p style="color: #cccccc">User ID</p>
                <div class="user-id">
                    <div class="notify-copy">
                        Copied!
                    </div>
                    <h4>{{user_info.student_code}}</h4>
                    <img @click="copyText(user_info.student_code)" src="/themes/web/assets/images/icon/icon-copy.svg" alt="">
                </div>
                <div class="user-reward">
                    <img src="/themes/web/assets/images/icon/icon-diamond-tiny.svg" alt="">
                    <p>{{ user_info.reward.diamond | toCommas}}</p>
                </div>
                <div class="user-reward">
                    <img style="position: fixed;" class="crown-tiny" src="/themes/web/assets/images/icon/icon-crown-tiny.svg" alt="">
                    <p style="margin-left: 40px">{{ user_info.reward.crown | toCommas}}</p>
                </div>
                <div class="user-avatar">
                    <img :src="user_info.avatar_url" alt="">
                </div>
                <button style="visibility: hidden;" class="btn-change-avatar" @click="showChangeAvatarModal()">Change Picture</button>
            </div>
            <div style="margin: 0;">
                <!-- <div class="line"></div> -->
                <!-- <div class="change-password" @click="showChangePasswordModal()">change password</div> -->
                <h4 style="color: #FF3D54;  letter-spacing: 0.02em; font-weight: 700; font-size: 16px; cursor: pointer; margin: 0; padding-bottom: 32px;" @click="onLogout()">LOG OUT</h4>
            </div>
            <!-- <div class="upgrade">upgrade</div> -->
        </div>
        <div v-if="!is_transcript" class="right">
                <div class="top">
                    <!-- <div style="width: 100%; display: flex; justify-content: flex-end;">
                        <button class="btn-save">Save</button>
                    </div> -->
                    <div class="form-group" style="position: relative;" @mouseover="showTooltip(1)" @mouseleave="hideTooltip(1)">
                        <p>Full Name</p>
                        <input type="text" disabled :placeholder="user_info.fullname">
                        <div class="tooltip" v-show="isTooltipVisible && user_info.fullname.length >= 49" :style="{ top: `80px`, right: `40%` }">
                            <div class="tooltip-arrow"></div>
                            <span>{{user_info.fullname}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <p>Username</p>
                        <input type="text" disabled :placeholder="user_info.username">
                    </div>
                    <div class="form-group" style="position: relative;" @mouseover="showTooltip(2)" @mouseleave="hideTooltip(2)">
                        <p>Email</p>
                        <input type="text" disabled :placeholder="user_info.email">
                        <div class="tooltip" v-show="isTooltipVisible1 && user_info.email.length >= 49" :style="{ top: `80px`, right: `40%` }">
                            <div class="tooltip-arrow"></div>
                            <span>{{user_info.email}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <p>Time zone</p>
                        <input type="text" disabled :placeholder="user_info.timezone">
                    </div>
                    <div class="list-group">
                        <div class="form-group">
                            <p>Phone Number</p>
                            <input style="width: 220px;" type="text" disabled :placeholder="user_info.phone">
                        </div>
                        <div class="form-group">
                            <p>Grade</p>
                            <input type="text" disabled :placeholder="user_info.grade ? `Grade ${user_info.grade}` : ''">
                        </div>
                    </div>
                    <div class="list-group">
                        <div class="form-group">
                            <p style="white-space: nowrap;">Date of Birth</p>
                            <input style="width: 220px;" type="text" disabled :placeholder="user_info.birthday | formatDate_1">
                        </div>
                        <div class="form-group">
                            <p>Gender</p>
                            <input type="text" disabled :placeholder="user_info.gender">
                        </div>
                    </div>
                    <div class="list-group">
                        <div class="form-group">
                            <p style="white-space: nowrap;">Nationality</p>
                            <input style="width: 220px;" type="text" disabled :placeholder="user_info.nationality">
                        </div>
                    </div>
                    <!-- <div class="list-group">
                        <div class="form-group">
                            <p>Class</p>
                            <input type="text" disabled placeholder="Class Name">
                        </div>
                        <div class="form-group">
                            <p>Grade</p>
                            <input type="text" disabled :placeholder="'Grade' + profile.grade">
                        </div>
                    </div>
                    <div class="list-group">
                        <div class="form-group">
                            <p>Account Type</p>
                            <input type="text" disabled placeholder="Basic">
                        </div>
                        <div class="form-group">
                            <p>Expiration Date</p>
                            <input type="text" disabled placeholder="31 DEC 2023">
                        </div>
                    </div>
                    <div class="form-group last">
                        <p>Learning since</p>
                        <span>2023-01-01</span>
                    </div> -->
                </div>
                <div class="bottom">
                    <!-- <h4 style="color: #FF3D54" @click="onLogout()">LOG OUT</h4> -->
                    <!-- <h4>DISABLE MY ACCOUNT</h4>
                    <h4 class="delete">DELETE MY ACCOUNT</h4> -->
                    <div class="show-transcript">
                        <p>View & download your transcript</p>
                        <button @click="showTranscriptModal()" class="btn-show-transcript">
                            <img src="/themes/web/assets/images/icon/transcript.svg" alt="">
                            <p>Your Transcript</p>
                        </button>
                    </div>
                </div>
        </div>
        <div id="transcript" v-if="is_transcript">
            <h3>{{ user_info.fullname }}'s Transcript</h3>
            <div class="group-list">
                <h5>Program</h5>
                <div @click="showListProgram()" class="input-choose">
                    <span>{{ program_name != '' ? program_name : 'Select a program' }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                        <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div>
                    <p v-if="show_program && filtered_programs.length == 0" class="p-1">The student has not been assigned to a program. Please, contact the admin team to support.</p>
                    <p v-if="validate_program" class="p-1">Please, select a program</p>
                </div>
                <div class="list-item" v-if="show_program">
                    <div v-for="p in filtered_programs" :key="p.id" @click="getTemplate(p)" class="item">
                        {{ p.name }}
                    </div>
                </div>
            </div>
            <div class="group-list">
                <h5>Templete</h5>
                <div @click="showListTemplate()" :class="template_name != '' ? 'input-choose' : 'input-choose disabled'">
                    <span>{{ template_name != '' ? template_name : 'Select a template'}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                        <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div>
                        <p v-if="selected_program != null && selected_program.transcript_template == ''" class="p-1">The program has no transcript template. Please, contact the admin team to support.</p>
                    </div>
                    <!-- <div class="list-item" v-if="template_name">
                        <div class="item">
                            {{ template_name }}
                        </div>
                    </div> -->
                </div>
                <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
                    <button @click="viewTranscript()" class="btn-show-transcript view">
                        <p>View Transcript</p>
                    </button>
                    <button @click="downloadTranscript()" class="btn-show-transcript download">
                        <p>Download Transcript</p>
                    </button>
                </div>
              
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import changePasswordModal from "../modal/home/changePasswordModal.vue";
import changeAvatarModal from "../modal/home/changeAvatarModal.vue";
import confirmLogout from "../modal/home/confirmLogout.vue";

import {getAvatars, getProgramsByUser, getAllPrograms, viewTranscript} from "../../models/profile"

export default {
    name: "Profile",
    title: 'Profile',

    components: {changePasswordModal, changeAvatarModal, confirmLogout},

    data() {
        return {
            show_program: false,
            is_transcript: false,
            show_template: false,
            program_name: '',
            template_name: '',
            listAvatar: [],
            program_ids: [],
            all_programs: [],
            selected_program: null,
            filtered_programs: [],
            isTooltipVisible: false,
            isTooltipVisible1: false,
            tooltipPosition: {
                top: 0,
                left: 0
            },
            validate_program: false
        };
    },

    created() {
        // this.$store.commit('setMenuActive', 'inbox');
        this.getAvatars()
    },

    computed: {
        user_info() {
            return this.$store.state.user_info;
        },
    },

    methods: {
        showTooltip(n) {
            // const tooltip = event.currentTarget.querySelector('.tooltip');
            // const rect = event.currentTarget.getBoundingClientRect();
            // const tooltipHeight = tooltip.offsetHeight;
            // const tooltipWidth = tooltip.offsetWidth;
            
            // this.tooltipPosition.top = rect.top - tooltipHeight - 10;
            // this.tooltipPosition.left = rect.left + rect.width / 2 - tooltipWidth / 2;
            if(n==1){
                this.isTooltipVisible = true;
            }else if(n==2){
                this.isTooltipVisible1 = true;
            }
        },
        hideTooltip(n) {
            if(n==1){
                this.isTooltipVisible = false;
            }else if(n==2){
                this.isTooltipVisible1 = false;
            }
        },

        viewTranscript(){
            if(this.selected_program == null){
                this.validate_program = true;
                return;
            }
            const params = {
                user_id : this.user_info.nosql_id,
                "program_ids[]" : this.selected_program.id,
                template : this.selected_program.transcript_template,
                type : "unofficial"
            }
            axios.all([
                viewTranscript(params),
            ]).then(axios.spread((response) => {
                const data = response.data;
                const dataUrl = URL.createObjectURL(data);
                window.open(dataUrl, '_blank');
            }));
        },
        downloadTranscript(){
            if(this.selected_program == null){
                this.validate_program = true;
                return;
            }
            const params = {
                user_id : this.user_info.nosql_id,
                "program_ids[]" : this.selected_program.id,
                template : this.selected_program.transcript_template,
                type : "unofficial"
            }
            axios.all([
                viewTranscript(params),
            ]).then(axios.spread((response) => {
                const data = response.data;

                const dataUrl = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'transcript_data';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }));
        },
        getTemplate(program){
            this.validate_program = false;
            this.selected_program = program
            this.show_program = false;
            this.program_name = program.name
            this.template_name = program ? program.transcript_template : ''
        },
        showListProgram(){
            this.show_program = !this.show_program;
        },
        showListTemplate(){
            this.show_template = !this.show_template
        },
        getAvatars(){
            // axios.all([
            //     getAvatars(),
            // ]).then(axios.spread((response) => {
            //     if (response.data.code != 0) {
            //         return;
            //     }

            //     this.listAvatar = response.data.results.items || [];
            // }));
        },
        async showChangePasswordModal(){
            const cfm = await this.$refs.changePassword.show({
                lang: this.lang,
            });
            if (!cfm) {
                return false;
            }
        },
        async showChangeAvatarModal(){
            const cfm = await this.$refs.changeAvatar.show({
                lang: this.lang,
                listAvatar : this.listAvatar
            });
            if (!cfm) {
                return false;
            }
        },
        async showTranscriptModal(){
            this.is_transcript = true;

            await axios.all([
                getProgramsByUser(this.user_info.nosql_id),
            ]).then(axios.spread((response) => {
                this.program_ids = response.data.program_ids;
            }));

            await axios.all([
                getAllPrograms(),
            ]).then(axios.spread((response) => {
                this.all_programs = response.data.data;
            }));

            this.filtered_programs = this.all_programs.filter(program => {
                return this.program_ids.includes(program.id);
            });
        },
        copyText(text) {
            navigator.clipboard.writeText(text);

            setTimeout(function() {
                $('.notify-copy').fadeOut();
            }, 1000);

            $('.notify-copy').fadeIn();
        },
        async onLogout(e) {
            const cfm = await this.$refs.confirmLogout.show({
                lang: this.lang,
            });
            if (cfm) {
                this.$store.dispatch('logout').then(() => {
                    localStorage.removeItem("access_token");
                    localStorage.removeItem(`warning_timezone_${this.user_info.username}`);
                    this.$router.push({name: 'login'})
                });
            }
        },
    }
}
</script>

<style scoped>  
    .tooltip-container {
        /* display: inline-block; */
        position: relative;
    }

    .tooltip {
        position: absolute;
        color: #000;
        background: #fff;
        padding: 12px 24px;
        border-radius: 12px;
        white-space: nowrap;
        transform: translateY(-10px);
        z-index: 10;
        border: 1px solid #333;
        font-size: 14px;
        font-weight: 500;
        max-width: 320px;
        text-wrap: wrap;
        overflow-wrap: break-word;
    }

    .tooltip-arrow {
        position: absolute;
        top: -10px;
        left: 45%;
        transform: translateX(100%);
        transform: rotateZ(45deg);
        width: 18px;
        height: 18px;
        background: #fff;
        /* border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #fff; */
        border-left: 1px solid #000;
        border-top: 1px solid #000;
    }
    .tooltip span{
        z-index: 99;
        /* position: absolute;
        z-index: 99; */
    }


    .p-1{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-align: left;
        color: #FF3D54;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    #transcript .item{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #1D1D1F;
        border-bottom: 1px solid #CFCFCF;
        padding: 16px;
        height: 45px;
        background: #fff;
        cursor: pointer;
    }
    #transcript .item:hover{
        background:  #CFCFCF;
    }
    #transcript .list-item{
        position: absolute;
        width: 100%;
        right: 0;
        left: 0;
        z-index: 2;
        top: 79px;
        border: 1px solid #CFCFCF;
    }
    #transcript{
        flex: 1;
        padding: 0 50px;
    }
    #transcript .group-list{
        margin-bottom: 10px;
        position: relative;
    }
    #transcript .input-choose{
        cursor: pointer;
        width: 100%;
        height: 55px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #CFCFCF;
        box-shadow: 2px 2px 0px 0px #CFCFCF;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #transcript .input-choose.disabled{
        background: #DDDDDD;
    }
    #transcript h3{
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #1D1D1F;
        text-align: center;
        margin-bottom: 30px;
    }
    #transcript h5{
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #1D1D1F;
        margin-bottom: 2px;
    }
    #transcript span{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #777777;
        flex: 1;
    }
    .show-transcript{
        background: #F8FAFC;
        padding: 24px;
        gap: 20px;
        border-radius: 24px;
        display: flex;
        align-items: center;
    }
    .show-transcript p{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #1D1D1F;
    }
    #profile{
        display: flex;
        justify-content: space-between;
    }
    .left{
        border: 2px solid #DDDDDD;
        border-radius: 18px;
        width: 400px;
        /* height: 590px; */
    }
    .user-reward{
        color: #000000;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 4px;
    }
    .user-reward img{
        margin-right: 4px;
    }
    /* .crown-tiny{
        position: absolute;
        left: 200px;
    } */
    .left h4{
        color: #000000;
        font-weight: 700;
        font-size: 32px;
        margin-right: 16px;
    }
    .left>p{
        color: #CFCFCF;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 8px;
    }
    .left{
        padding: 30px 0;
        padding-bottom: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .user-id{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
    }
    .user-id img{
        cursor: pointer;
    }
    .user-avatar{
        width: 160px;
        height: 160px;
        overflow: hidden;
        border: 4px solid #5B46B1;
        border-radius: 24px;
        margin: auto;
        margin-bottom: 32px;
        margin-top: 10px;
    }
    .user-avatar img{
        height: 100%;
        /* height: 160px; */
    }
    .btn-change-avatar{
        display: none;
        height: 52px;
        width: 179px;
        text-align: center;
        color: #A560EB;
        font-weight: 700;
        font-size: 16px;
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #CFCFCF;
        border-radius: 48px;
        cursor: pointer;
        margin-bottom: 32px;
        background: #FFFFFF;
    }
    .btn-change-avatar:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .btn-show-transcript.view{
        margin-top: 30px;
    }
    .btn-show-transcript.view,  .btn-show-transcript.download{
       width: 381px;
       color: #191715;
    }
    .btn-show-transcript.download{
        border: 1px solid #E3E87A;
        background: #F8FE5F;
        margin-top: 20px;
       box-shadow: 4px 4px 0px 0px #E3E87A;
    }
    .btn-show-transcript{
        height: 52px;
        width: 179px;
        text-align: center;
        color: #A560EB;
        font-weight: 700;
        font-size: 16px;
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #CFCFCF;
        border-radius: 48px;
        cursor: pointer;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    .btn-show-transcript:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .btn-save{
        height: 52px;
        background: #DDDDDD;
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #CFCFCF;
        border-radius: 48px;
        font-weight: 700;
        font-size: 16px;
        padding: 0 16px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .btn-save:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .line{
        border: 1px solid #CFCFCF;
        width: 100%;
    }
    .change-password{
        margin-top: 20px;
        margin-bottom: 24px;
        color: #EAD4FF;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        cursor: pointer;
    }
    .upgrade{
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #4C4C4C;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
    }
    .right{
        width: 100%;
        width: 600px;
    }
    .right input{
        height: 53px;
        background: #F8FAFC;
        border: 1px solid #CFCFCF;
        border-radius: 16px;
        padding: 24px;
        letter-spacing: 0.02em;
        font-size: 16px;
        color: #0D0D0C;
        font-weight: 400;
        width: 484px;
        text-overflow: ellipsis;
        /* width: 464px; */

    }
    .right .form-group{
        display: flex;
        /* justify-content: space-between; */
        margin-bottom: 8px;
        align-items: center;
        gap: 16px;
    }
    .list-group{
        display: flex;
        justify-content: space-between;
    }
    .right .form-group p{
        /* white-space: nowrap; */
        letter-spacing: 0.02em;
        color: #0D0D0C;
        font-weight: 700;
        font-size: 16px;
        width: 100px;
        /* width: 120px; */
        display: flex;
        justify-content: flex-end;
        text-align: end;
    } 
    .form-group.last{
        margin-top: 25px;
    }
    .form-group.last span{
        letter-spacing: 0.02em;
        color: #AFAFAF;
        font-weight: 400;
        font-size: 16px;
    }
    .list-group input{
        width: 148px;
        /* width: 164px; */
    }
    .bottom{
        /* margin-top: 53px;
        margin-left: calc(120px + 16px); */
    }
    .bottom>h4{
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 16px;
        color: #AFAFAF;
        cursor: pointer;
        margin-bottom: 16px;
    }
    .bottom>h4.delete{
        color: #AFAFAF;
        color: #FF003D;
    }
    .notify-copy{
        padding: 8px 16px;
        background-color: rgb(88, 199, 103);
        border-radius: 4px;
        position: absolute;
        right: 40px;
        top: -40px;
        display: none;
    }
</style>
